import { Component, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements AfterViewInit {
  loading = true;
  constructor() { }

  ngAfterViewInit() {
    this.detectLoadImg();
  }


  detectLoadImg() {

    window.onload = () => {
      let count = 0;
      var image = document.querySelectorAll('img');
      image.forEach(i => {
        i.complete && (count ++)
          if (count == image.length) {
            this.loading = false;
          }        
      });
    }


  }



}




