<div class="row mx-0 p-0" style="height: 100vh; position: static;width: 100%;">
    <div class="col-12 p-0">
        <carousel [arrows]="false" [images]="images"  [height]="height" [cellsToShow]="1" [autoplay]="true"
            [transitionTimingFunction]="'ease-in'">
        </carousel>
        <div class="line-slide pt-5 animate__animated animate__fadeIn ">
            <div class="row m-0 h-25 d-flex align-items-center">
                <div class="col-12 pl-2">
                    <h2 class="animate__animated animate__backInLeft">ADMINISTRACIÓN DE <span class="text-danger">ARCHIVOS</span> </h2>
                </div>
            </div>
            <div class="row ">
                <div class="col-12">
                    <h4 class="animate__animated animate__fadeIn animate__delay-1s"><i class="fas fa-check text-success mr-2"></i> MANEJA TU DOCUMENTACIÓN DE FORMA DIGITAL</h4>
                </div>
            </div>
            <div class="row ">
                <div class="col-12 pt-4">
                    <h4 class="animate__animated animate__fadeIn animate__delay-2s"><i class="fas fa-check text-success mr-2"></i> TODO RESGUARDADO CON LA MÁXIMA SEGURIDAD</h4>
                </div>
            </div>         
            <div class="row ">
                <div class="col-12 pt-4">
                    <h4 class="animate__animated animate__fadeIn animate__delay-3s"><i class="fas fa-check text-success mr-2"></i> RESPONSABILIDAD 100%</h4>
                </div>
            </div> 
            <div class="row  pt-5">
                <div class="col-12 d-flex justify-content-start animate__animated animate__fadeIn animate__delay-3s">
                    <img class="img-go" src="./assets/images/carousel/go.png" alt="go">
                    <img class="img-uv" src="./assets/images/carousel/logo_tuv.png" alt="tuv">
                </div>              
            </div>      
        </div>
    </div>
</div>

