import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css']
})
export class ClientesComponent implements OnInit {

  constructor(
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {   
  }

}
