import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {
  images = [
    {
      path: './assets/images/carousel/1.jpg'
    },
    {
      path: './assets/images/carousel/2.jpg'
    },
    {
      path: './assets/images/carousel/dig.JPG'
    }
  ];
  height: number;
  width: number;
  constructor() { 
   this.height = window.innerHeight;
   this.width = window.innerWidth;
  }

  ngOnInit(): void {
  }

}
