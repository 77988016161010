import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { ServiciosComponent } from './components/servicios/servicios.component';
import { ParallaxNosotrosComponent } from './components/parallax/parallax-nosotros/parallax-nosotros.component';
import { ParallaxScrollModule } from 'ng2-parallaxscroll';
import { ClientesComponent } from './components/clientes/clientes/clientes.component';
import { EquipoComponent } from './components/equipo/equipo/equipo.component';
import { BeneficiosComponent } from './components/beneficios/beneficios.component';
import { MapsComponent } from './components/maps/maps.component';
import { FooterComponent } from './components/footer/footer.component';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { GaleriaComponent } from './components/galeria/galeria.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { FranquiciasComponent } from './components/franquicias/franquicias.component';
import { CalidadComponent } from './components/calidad/calidad.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    CarouselComponent,
    ServiciosComponent,
    ParallaxNosotrosComponent,
    ClientesComponent,
    EquipoComponent,
    BeneficiosComponent,
    MapsComponent,
    FooterComponent,
    GaleriaComponent,
    InicioComponent,
    FranquiciasComponent,
    CalidadComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    IvyCarouselModule,
    ParallaxScrollModule,
    NgxLoadingModule.forRoot({ animationType: ngxLoadingAnimationTypes.rotatingPlane,
      backdropBackgroundColour: '#f2f2f2', 
      backdropBorderRadius: '0px',
      primaryColour: '#4D80D7', 
      secondaryColour: 'red', 
      tertiaryColour: 'white',
      fullScreenBackdrop: true})
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
