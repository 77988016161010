import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parallax-nosotros',
  templateUrl: './parallax-nosotros.component.html',
  styleUrls: ['./parallax-nosotros.component.css']
})
export class ParallaxNosotrosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
