<div class="row fondo mx-0 d-flex align-content-center">
  <div class="col-12 p-5">
    <h1 class="text-center display-3 text-white">
      Política de Calidad (S.G.I.)
    </h1>
  </div>
</div>

<div class="row m-0 mt-3">
  <div class="col-12 m-0 px-5">
    <h4 class="mt-2">
      1. POLÍTICA DEL SGI (CALIDAD + SEGURIDAD DE LA INFORMACIÓN), versión fecha
      15/6/2023
    </h4>
    <hr />
    <p class="text-start">
      En INTERFILE desarrollamos un programa de trabajo que induce a nuestros
      clientes a producir un cambio cultural en su visión del sector de archivos
      y resguardo de información en sus diferentes formatos. De esta forma
      jerarquizamos a los mismos haciendo observar su real importancia en el
      contexto organizacional, garantizando la gestión y preservación de sus
      activos documentales. Esto genera el compromiso de optimizar nuestros
      procesos, buscando satisfacer las necesidades de los clientes a través de
      la participación permanente de todo nuestro personal aportando
      experiencia, tecnología y honestidad, para garantizar en el mercado los
      objetivos irrenunciables para un archivo moderno, además de contribuir con
      nuestras acciones de reciclado a la preservación del ambiente. Para ello
      establecemos nuestra Política integrada de Calidad y Seguridad de la
      Información, con el objetivo de cumplir con los requisitos y mejorar
      continuamente la eficiencia del Sistema de Gestión de Calidad y Seguridad
      de la Información implementados bajo las Normas ISO 9001 e ISO 27001
      Apuntalado en estos 3 pilares:
    </p>
    <p>
      <b class="text-decoration-underline">DISPONIBILIDAD:</b> proveer en forma
      rápida, correcta y sencilla la documentación y/o información, por la vía
      más precisa y conveniente en cada caso.
    </p>
    <p>
      <b class="text-decoration-underline">SEGURIDAD:</b> preservar los archivos
      durante su vida útil, con estrictas medidas que garanticen la
      confidencialidad e integridad de los activos de información de la
      organización y de sus partes interesadas.
    </p>
    <p>
      <b class="text-decoration-underline">COSTOS:</b> a disponibilidad y
      seguridad antes mencionada, se da en un marco de ahorro de costos y
      eficiencia de recursos para todas las partes involucradas en el negocio.
      Desde la Dirección asumimos el compromiso de aplicar esta Política junto a
      la integración activa de todo el capital humano de la empresa, promoviendo
      la concientización de estos recursos mediante su entrenamiento permanente.
    </p>   
    <h4 class="mt-4">
      2. Política de dispositivos móviles y teletrabajo, versión fecha 15/6/2023
    </h4>
    <hr />
    <p class="text-start">
      En INTERFILE se cuenta con una política que restringe el acceso remoto a
      la red y a los servicios digitales a usuarios sin niveles de encriptación
      aceptables. De esta manera se garantiza la confidencialidad, integridad y
      disponibilidad de la información en cualquier situación de trabajo local o
      remoto. Los controles criptográficos se encuentran definidos y
      documentados. En cuanto a los dispositivos móviles, se garantiza la
      seguridad de la información, fundamentada en la segmentación e
      identificación sobre la red que se conecta el usuario teniendo distintos
      privilegios de acceso. Los controles de acceso a las redes y a los
      servicios de red se encuentran definidos y documentados.
    </p>
    <h4 class="mt-4">
      3. Política de confidencialidad con partes internas y externas, versión
      fecha 15/6/2023
    </h4>
    <hr />
    <p class="text-start">
      INTERFILE garantiza la confidencialidad del manejo de los activos de
      información en todos sus formatos por medio de acuerdos de reserva y
      confidencialidad que han firmado oportunamente empleados y proveedores y
      otras eventuales partes interesadas que pueden acceder por sus funciones
      laborales a este tipo de información. Dichos acuerdos se encuentran
      documentados y archivados, y se actualizan cuando nuevas partes
      interesadas tengan injerencia en los activos de la empresa.
    </p>
    <h4 class="mt-4">
      4. Política de transferencia de información, versión fecha 15/6/2023
    </h4>
    <hr />
    <p class="text-start">
      INTERFILE establece una política de centralización sobre el análisis,
      detección y filtrado de la transferencia de información, determinada por
      la segmentación interna de las redes y el control de tráfico digital que
      pasa por el Firewall - IPS. Los controles relacionados con la gestión de
      la seguridad de la red se encuentran definidos y documentados.
    </p>
    <h4 class="mt-4">
      5. Política de control de accesos, versión fecha 15/6/2023
    </h4>
    <hr />
    <p class="text-start">Interfile ha establecido una política de acceso a</p>
    <p>
      a) los activos de información digital a través de la identificación de
      usuarios mediante credenciales, y un servicio centralizado de directorio
      de actividades (Active Directory) donde se definen los usuarios con los
      perfiles de acceso para cada caso. Los controles relacionados con la
      gestión de accesos a activos digitales se encuentran definidos y
      documentados.
    </p>
    <p>
      b) los activos de información físicos, a través de controles de acceso a
      los colaboradores y otras partes interesadas por medios de identificación
      personal, barreras físicas, claves de ingreso a sectores, filmación de
      movimientos y alarmas que disuaden cualquier acceso no autorizado. Los
      controles relacionados con la gestión de accesos a activos físicos se
      encuentran definidos y documentados.
    </p>
    <h4 class="mt-4">
      6. Política de uso de controles criptográficos, versión fecha 15/6/2023
    </h4>
    <hr />
    <p class="text-start">
      En INTERFILE se encuentra definida una política de controles
      criptográficos. La misma se aplica para todas las conexiones remotas a
      servicios e información digital utilizada por la organización, por medio
      de certificados digitales de seguridad, claves de accesos, redes privadas
      virtuales o VPNs, y aplicaciones que encriptan cualquier dato que deba
      salir externamente de INTERFILE. Los controles relacionados con la gestión
      de la encriptación se encuentran definidos y documentados.
    </p>
    <h4 class="mt-4">
      7. Política de control de escritorio y pantalla limpia, versión fecha
      15/6/2023
    </h4>
    <hr />
    <p class="text-start">
      INTERFILE ha adoptado una política de escritorio y pantalla limpia. Por
      ello todos los puestos de trabajo informáticos han sido programados para
      que luego de un tiempo prudencial eviten ser accedidos sin una clave
      individual de ingreso (pantalla limpia). Además, el personal de la empresa
      ha sido entrenado para el orden y limpieza en los escritorios y entornos
      físicos de modo de mantener la confidencialidad, integridad y
      disponibilidad de la información física que gestionan (escritorio limpio).
      Los controles relacionados con pantalla y escritorio limpio se realizan a
      través de auditorías programadas o sorpresivas.
    </p>
    <h4 class="mt-4">
      8. Política de desarrollo seguro, versión fecha 15/6/2023
    </h4>
    <hr />
    <p class="text-start">
      INTERFILE ha establecido una política de desarrollo seguro para el diseño
      de aplicaciones de software. Esto abarca las mejores prácticas que se usan
      siendo parte del ciclo de vida de desarrollo, incluyendo la seguridad del
      contexto de desarrollo y las herramientas utilizadas. La organización
      adopta dichas prácticas para evaluar y demostrar la seguridad en cada
      fase.
    </p>
  </div>
</div>
