import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  sendMail = false;
  error = false;

  constructor(private http: HttpClient) {

  }

  ngOnInit(): void {
  }

  onSubmit(contactForm: NgForm) {
    if (contactForm.valid) {
      const email = contactForm.value;
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      this.http.post('https://formspree.io/f/mqkwezqj',
        { name: email, message: 'Buenos dias, me gustaria contactarme con ustedes para saber de sus servicios.' },
        { 'headers': headers }).subscribe(
          response => {
            this.sendMail = true;
            contactForm.reset();
          },
          error => {
            this.error = true;            
          }
        );
    }
  }

}
