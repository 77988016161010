
<div id="nosotros" class="row m-0 p-0 pt-5" style="margin-top: 120px !important;">    
    <div class="col-12 p-0">       
        <img id="comillas" src="./assets/images/parallax/Comillas.png">
        <div parallax class="img">               
            <h1 class="text-white text-center pt-5">SOBRE NOSOTROS</h1>
            <p class="px-5 text-justify text-wrap">INTERFILE S.A. fué establecida en 1997, con el objetivo de actuar en el segmento de gestión y guarda de archivos empresarios, adoptando desde sus comienzos un programa de calidad y especialización en el rubro.</p>
        </div>
    </div>
</div>
