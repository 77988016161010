<div class="row fondo mx-0 d-flex align-content-center">
    <div class="col-12 p-5">
     <h1 class="text-center display-3 text-white">FRANQUICIAS</h1>
     <h4 class="text-center pt-3 text-white">Nuestras franquicias están distribuidas por todo el país, asegurando poder extender nuestros servicios de calidad</h4>
    </div>
</div>



<div class="row row-cols-1 row-cols-md-2 g-3 mx-0" style="margin-top: 60px;margin-bottom: 80px;">
    <div class="col">
      <div class="card shadow shadow-md">
        <iframe  class="card-img-top"  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3340.966716109482!2d-64.3591241848088!3d-33.136238980864945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95d20019393303ad%3A0x31b09e37f6e0a7f8!2sBernardo%20de%20O&#39;Higgins%202058%2C%20X5800%20R%C3%ADo%20Cuarto%2C%20C%C3%B3rdoba!5e0!3m2!1ses!2sar!4v1619525162364!5m2!1ses!2sar"  allowfullscreen="truetrue" loading="lazy"></iframe>
        <div class="card-body px-2">
          <h5 class="card-title"> Rio Cuarto, Córdoba</h5>
          <ul class="list-group">
              <li class="list-group-item border-0"> <i class="fa fa-address-book" aria-hidden="true"></i> O'Higgins 2058 - CP:5800</li>
              <li class="list-group-item border-0"><i class="fa fa-phone" aria-hidden="true"></i> (0358) 156007577</li>
              <li class="list-group-item border-0"><i class="fa fa-envelope" aria-hidden="true"></i> administracion@interfilerio4.com.ar</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col">
        <div class="card shadow shadow-md">            
            <iframe class="card-img-top" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3368.5002057191846!2d-63.245232085126155!3d-32.40575685204136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95cc42dc152519d9%3A0xdc383576e15742f7!2zQ2hpbGUgMzQ0LCBWaWxsYSBNYXLDrWEsIEPDs3Jkb2Jh!5e0!3m2!1ses!2sar!4v1619525860276!5m2!1ses!2sar"  allowfullscreen="true" loading="lazy"></iframe>
            <div class="card-body px-2">
              <h5 class="card-title"> Villa Maria, Córdoba</h5>
              <ul class="list-group">
                  <li class="list-group-item border-0"> <i class="fa fa-address-book" aria-hidden="true"></i> Chile 344 - CP:5900</li>
                  <li class="list-group-item border-0"><i class="fa fa-phone" aria-hidden="true"></i> (0353) 4532193 / 4531012</li>
                  <li class="list-group-item border-0"><i class="fa fa-envelope" aria-hidden="true"></i> busqueda@bdsa.com.ar</li>
              </ul>
            </div>
          </div>
    </div>
    <div class="col">
        <div class="card shadow shadow-md">            
            <iframe class="card-img-top"  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3561.084965699477!2d-65.20580368529689!3d-26.80542309519342!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94225c31f1bd34cb%3A0x9b9a15e394bf8a2c!2sColombia%20846%2C%20T4001CUR%20San%20Miguel%20de%20Tucum%C3%A1n%2C%20Tucum%C3%A1n!5e0!3m2!1ses!2sar!4v1619526100253!5m2!1ses!2sar"  allowfullscreen="true" loading="lazy"></iframe>
            <div class="card-body px-2">
              <h5 class="card-title">Tucumán</h5>
              <ul class="list-group">
                  <li class="list-group-item border-0"> <i class="fa fa-address-book" aria-hidden="true"></i> Colombia 846 - CP:4000 - S.M de Tucumán</li>
                  <li class="list-group-item border-0"><i class="fa fa-phone" aria-hidden="true"></i> (0381) 4274234 / 4216</li>
                  <li class="list-group-item border-0"><i class="fa fa-envelope" aria-hidden="true"></i> info@filesa.com.ar</li>
              </ul>
            </div>
          </div>
    </div>
    <div class="col">
        <div class="card shadow shadow-md">            
            <iframe class="card-img-top" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3540.1858028933902!2d-58.99320598527818!3d-27.46347432301255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94450c9366dfa4b5%3A0xd0ae2642e665e5a4!2sAv.%20San%20Mart%C3%ADn%201035%2C%20H3504%20Resistencia%2C%20Chaco!5e0!3m2!1ses!2sar!4v1619526332042!5m2!1ses!2sar"  allowfullscreen="true" loading="lazy"></iframe>
            <div class="card-body px-2">
              <h5 class="card-title"> Chaco</h5>
              <ul class="list-group">
                  <li class="list-group-item border-0"> <i class="fa fa-address-book" aria-hidden="true"></i> Av. San Martin 1035 - CP:3500 - Resistencia</li>
                  <li class="list-group-item border-0"><i class="fa fa-phone" aria-hidden="true"></i> (03624) 448164</li>
                  <li class="list-group-item border-0"><i class="fa fa-envelope" aria-hidden="true"></i> ultracard@ultracardchaco.com.ar</li>
              </ul>
            </div>
          </div>
    </div>
    <div class="col">
        <div class="card shadow shadow-md">                        
            <iframe class="card-img-top" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.808371639367!2d-65.72663558524924!3d-28.455192466139604!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94242f727cc9066d%3A0x9123c6da44de29ba!2sPadre%20Esqui%C3%BA%20757%2C%20San%20Isidro%2C%20Catamarca!5e0!3m2!1ses!2sar!4v1619526649203!5m2!1ses!2sar"  allowfullscreen="true" loading="lazy"></iframe>
            <div class="card-body px-2">
              <h5 class="card-title"> Catamarca</h5>
              <ul class="list-group">
                  <li class="list-group-item border-0"> <i class="fa fa-address-book" aria-hidden="true"></i> Padre Esquiú 757 - CP:4707 - San Isidro Valle Viejo</li>
                  <li class="list-group-item border-0"><i class="fa fa-phone" aria-hidden="true"></i> (0383) 443633</li>
                  <li class="list-group-item border-0"><i class="fa fa-envelope" aria-hidden="true"></i> comercial@interfilecat.com.ar</li>
              </ul>
            </div>
          </div>
    </div>
    <div class="col">
        <div class="card shadow shadow-md">                        
            <iframe class="card-img-top" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d863.8758047873565!2d-59.51665267080443!3d-29.9937038373642!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x944c8a8a0e2f659b%3A0x6b9c3341dc4a06f0!2sRN12%201030%2C%20Esquina%2C%20Corrientes!5e0!3m2!1ses!2sar!4v1619526922341!5m2!1ses!2sar"  allowfullscreen="true" loading="lazy"></iframe>
            <div class="card-body px-2">
              <h5 class="card-title"> Corrientes</h5>
              <ul class="list-group">
                  <li class="list-group-item border-0"> <i class="fa fa-address-book" aria-hidden="true"></i> Ruta 12km. 1030 - CP:3400</li>
                  <li class="list-group-item border-0"><i class="fa fa-phone" aria-hidden="true"></i> (03794) 453670</li>
                  <li class="list-group-item border-0"><i class="fa fa-envelope" aria-hidden="true"></i> ultracardctes@arnet.com.ar</li>
              </ul>
            </div>
          </div>
    </div>
    <div class="col">
        <div class="card shadow shadow-md">            
           
            <iframe class="card-img-top" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3317.8293089268254!2d-61.97504678479324!3d-33.73922968069223!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95c864a5abbb7903%3A0x9377ea72b9b69502!2sAvellaneda%20845%2C%20Venado%20Tuerto%2C%20Santa%20Fe!5e0!3m2!1ses-419!2sar!4v1621599384906!5m2!1ses-419!2sar" allowfullscreen="true" loading="lazy"></iframe>
            <div class="card-body px-2">
              <h5 class="card-title"> Santa Fe</h5>
              <ul class="list-group">
                  <li class="list-group-item border-0"> <i class="fa fa-address-book" aria-hidden="true"></i> Avellaneda 845 - CP:2600 - Venado Tuerto</li>
                  <li class="list-group-item border-0"><i class="fa fa-phone" aria-hidden="true"></i> (03462) 425776</li>
                  <li class="list-group-item border-0"><i class="fa fa-envelope" aria-hidden="true"></i> interfile@interfilevt.com.ar</li>
              </ul>
            </div>
          </div>
    </div>
    <div class="col">
        <div class="card shadow shadow-md">                                    
            <iframe class="card-img-top" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3201.832180916606!2d-64.27454628471487!3d-36.630425979983904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95c2cc4c06ce5a15%3A0x48da8491ebb0f10d!2sAv.%20Ing%20Santiago%20Marzo%20Este%201276%2C%20Santa%20Rosa%2C%20La%20Pampa!5e0!3m2!1ses-419!2sar!4v1621599267978!5m2!1ses-419!2sar" allowfullscreen="true" loading="lazy"></iframe>
            <div class="card-body px-2">
              <h5 class="card-title">La Pampa</h5>
              <ul class="list-group">
                  <li class="list-group-item border-0"> <i class="fa fa-address-book" aria-hidden="true"></i> Avda Circunvalación Stiago Marzo 1276 (entre Pasteur y Wilde) </li>
                  <li class="list-group-item border-0"><i class="fa fa-phone" aria-hidden="true"></i>  (3516) 26-9736</li>
                  <li class="list-group-item border-0"><i class="fa fa-envelope" aria-hidden="true"></i> -</li>
              </ul>
            </div>
          </div>
    </div>
  </div>

  <app-footer></app-footer>