<div class="row mx-0 bg-dark" style="min-height: 100vh;">
    <div class="col-12">        
        <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">           
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="./assets/images/galeria/InterFile34.jpg" alt="galery">
              </div>
              <div class="carousel-item">
                <img src="./assets/images/galeria/GED_1880.JPG" alt="galery">
              </div>
              <div class="carousel-item">
                <img src="./assets/images/galeria/InterFile05.jpg" alt="galery">
              </div>
              <div class="carousel-item">
                <img src="./assets/images/galeria/InterFile19.jpg" alt="galery">
              </div>
              <div class="carousel-item">
                <img src="./assets/images/galeria/InterFile07.jpg" alt="galery">
              </div>
              <div class="carousel-item">
                <img src="./assets/images/galeria/InterFile08.jpg" alt="galery">
              </div>
              <div class="carousel-item">
                <img src="./assets/images/galeria/InterFile19.jpg" alt="galery">
              </div>
              <div class="carousel-item">
                <img src="./assets/images/galeria/InterFile31.jpg" alt="galery">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
    </div>
</div>

<app-footer></app-footer>