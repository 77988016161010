<footer id="dk-footer" class="dk-footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="row p-2">
                    <div class="col-12 col-md-3">
                        <div class="contact-us">
                            <div class="contact-icon">
                                <i class="fa fa-map-o" aria-hidden="true"></i>
                            </div>
                            <div class="contact-info">
                                <h3>Córdoba, Argentina</h3>
                                <p>Jorge E. Ordóñez 86</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="contact-us contact-us-last">
                            <div class="contact-icon">
                                <i class="fa fa-volume-control-phone" aria-hidden="true"></i>
                            </div>
                            <div class="contact-info">
                                <h3>0351-4931414</h3>
                                <p>LLamanos para obtener mas información</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-5">
                        <div class="footer-widget">
                            <div class="section-heading">
                                <h3>Contáctanos</h3>
                            </div>
                            <p>
                                <!-- Don’t miss to subscribe to our new feeds, kindly fill the form below. -->
                                Ingresa tu correo electrónico y pronto se contactarán contigo
                            </p>
                            <form (ngSubmit)="onSubmit(contactForm)" #contactForm="ngForm">
                                <div class="form-row">
                                    <div class="col dk-footer-form">
                                        <input type="email" name="email" ngModel required #email="ngModel"
                                            class="form-control h-100 text-white" placeholder="Correo Electrónico">
                                        <button type="submit">
                                            <i class="fa fa-send"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <div *ngIf="sendMail" class="alert alert-success mt-2" role="alert">
                                Mail enviado con exito!!, pronto estaremos en contacto con usted.
                                <button type="button" class="btn-close" data-bs-dismiss="alert"
                                    aria-label="Close"></button>
                            </div>
                            <div *ngIf="error" class="alert alert-danger mt-2" role="alert">
                                Hubo un error inesperado, intente nuevamente.
                                <button type="button" class="btn-close" data-bs-dismiss="alert"
                                    aria-label="Close"></button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <span>Copyright © 2021, All Right Reserved INTERFILE S.A. Created By Mario Boscatto
                        (mariolucbos@hotmail.com)</span>
                </div>
            </div>
        </div>
    </div>

</footer>