<nav #nav class="navbar navbar-expand-lg fixed-top w-100">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">
           <img #logo src="./assets/images/IF.png" class="d-inline-block align-text-top"/>
      </a>
      <button class="navbar-toggler bg-dark" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <i class="fa fa-arrow-circle-down text-white" aria-hidden="true"></i>
      </button>
      <div class="collapse navbar-collapse w-100" id="navbarNav">
        <ul class="navbar-nav  w-100">
          <li class="nav-item">
            <a class="nav-link active " aria-current="page" href="#">Inicio</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#servicios">Servicios</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#nosotros">Sobre Nosotros</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#clientes">Clientes</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#equipo">Equipo</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/franquicias']">Franquicias</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/galeria']">Galería</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/calidad']">Política de Calidad</a>
          </li>
          <li class="nav-item">
            <a class="nav-link btn btn-outline-danger text-white" href="https://www.interfileweb.com.ar/principal">Ingresar</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

