import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.sass']
})
export class ServiciosComponent implements OnInit {
 constructor(){}
  ngOnInit(): void {
  }

}
