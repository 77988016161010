import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calidad',
  templateUrl: './calidad.component.html',
  styleUrls: ['./calidad.component.sass']
})
export class CalidadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
