import { Component, ElementRef, HostListener, AfterViewInit, OnInit, Renderer2, ViewChild } from '@angular/core';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, AfterViewInit {

  @ViewChild('nav', { static: false }) navElement = new ElementRef({});
  @ViewChild('logo', { static: false }) logoElement = new ElementRef({});
  constructor(
    private renderer: Renderer2,
  ) {

  }


  ngOnInit(): void {
    
  }

  ngAfterViewInit() {
    this.renderer.addClass(this.navElement.nativeElement, 'bg-initial');
    this.renderer.addClass(this.logoElement.nativeElement, 'logImgInit');
  }


  @HostListener("window:scroll", ['$event'])
  onWindowScroll($event: any) {
    let nav = this.navElement.nativeElement;
    let logo = this.logoElement.nativeElement;
    let scrollOffset = $event.srcElement.children[0].scrollTop;

    if (scrollOffset == 0) {
      this.renderer.addClass(nav, 'bg-initial');
      this.renderer.addClass(logo, 'logImgInit');
      this.renderer.removeClass(logo, 'logImgAfter');
      this.renderer.removeClass(nav, 'bg-after');
    }
    else {
      this.renderer.removeClass(nav, 'bg-initial');
      this.renderer.addClass(nav, 'bg-after');
      this.renderer.removeClass(logo, 'logImgInit');
      this.renderer.addClass(logo, 'logImgAfter');

    }
  }

}
