<div class="row m-0 mb-5 pb-4"> 
    <div class="col-12 col-md-5 img">

    </div>   
    <div class="col-12 col-md-7 text-center text-justify">
    <h4>Beneficios de la tercerización de la gestión de archivos y documentos</h4>
    <p>Optimizar costos a través de la revisión de los procesos relacionados.</p>
    <h4>Liberar espacios ocupados con documentación de archivo.</h4>
    <p>Mejorar el control y conservación de la documentación, garantizando su disposición inmediata, con acceso a bases
        informáticas instaladas directamente en la web.</p>
    <h4>Aministración In Company</h4>
    <p>Interfile S.A. le ofrece recursos humanos y tecnológicos para desempeñarse en su empresa, en forma parcial o full time
        logrando que sus archivos de mayor consulta se encuentren en perfecto orden, brindando respuesta inmediata, con el menor
        costo y con la menor ocupación de espacio.</p>
    <h4>Alquiler de Espacios Exclusivos</h4>
    <p>Boxes completamente cerrados, cuya superficie varía entre los 25 y 140 m2, con una altura de estiba de 4 metros,
        especialmente acondicionados para la guarda de elementos diversos, tales como papelería, formularios, mobiliario,
        mercaderías en general.</p>
    </div>
</div>
