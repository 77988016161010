<div id="equipo" class="row mx-0" style="margin-top: 100px;margin-bottom: 150px;padding-top: 90px;">
  <h2 class="text-center mb-3 text-uppercase">Nuestro Equipo</h2>
  <h4 class="text-center">A continuación se muestran parte del equipo de Interfile</h4>
  <div class="col-12">
    <div id="carouselExampleControls" class="carousel slide carousel-fade m-0 p-0" data-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="d-md-flex justify-content-center">
            <div class="card text-left shadow shadow-sm">
              <img class="card-img-top" src="./assets/images/team/jorge.JPG" alt="empleados">
              <div class="card-body bg-primary">
                <h4 class="card-title text-white">Jorge Piña</h4>
                <p class="card-text text-white">Director</p>
              </div>
            </div>
            <div class="card text-left ">
              <img class="card-img-top shadow shadow-sm" src="./assets/images/team/sil.JPG" alt="empleados">
              <div class="card-body bg-danger">
                <h4 class="card-title text-white">Silvana Ledesma</h4>
                <p class="card-text text-white">Responsable de Facturacion</p>
              </div>
            </div>
            <div class="card text-left  shadow shadow-sm">
              <img class="card-img-top" src="./assets/images/team/franco.JPG" alt="empleados">
              <div class="card-body bg-danger">
                <h4 class="card-title text-white">Franco Gonzalez</h4>
                <p class="card-text text-white">Desarrollador Informatico</p>
              </div>
            </div>
            <div class="card text-left  shadow shadow-sm">
              <img class="card-img-top" src="./assets/images/team/roberto.JPG" alt="empleados">
              <div class="card-body bg-primary">
                <h4 class="card-title text-white">Roberto Miguel</h4>
                <p class="card-text text-white">Responsable Comercial</p>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="d-md-flex justify-content-center">
            <div class="card text-left shadow shadow-sm">
              <img class="card-img-top" src="./assets/images/team/juan.JPG" alt="empleados">
              <div class="card-body bg-primary">
                <h4 class="card-title text-white">Juan Paez</h4>
                <p class="card-text text-white">Responsable Devolucion</p>
              </div>
            </div>
            <div class="card text-left ">
              <img class="card-img-top shadow shadow-sm" src="./assets/images/team/carola.JPG" alt="empleados">
              <div class="card-body bg-danger">
                <h4 class="card-title text-white">Carola Velazquez</h4>
                <p class="card-text text-white">Responsable Procesamiento de Datos</p>
              </div>
            </div>
            <div class="card text-left  shadow shadow-sm">
              <img class="card-img-top" src="./assets/images/team/zule.JPG" alt="empleados">
              <div class="card-body bg-danger">
                <h4 class="card-title text-white">Zulema Calderon</h4>
                <p class="card-text text-white">Responsable Producción</p>
              </div>
            </div>
            <div class="card text-left  shadow shadow-sm">
              <img class="card-img-top" src="./assets/images/team/leo.JPG" alt="empleados">
              <div class="card-body bg-primary">
                <h4 class="card-title text-white">Leonardo Flores</h4>
                <p class="card-text text-white">Responsable Almacen</p>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="d-md-flex justify-content-center">
            <div class="card text-left shadow shadow-sm">
              <img class="card-img-top" src="./assets/images/team/perla.JPG" alt="empleados">
              <div class="card-body bg-primary">
                <h4 class="card-title text-white">Perla Arreguiz</h4>
                <p class="card-text text-white">Responsable Consultas</p>
              </div>
            </div>
            <div class="card text-left ">
              <img class="card-img-top shadow shadow-sm" src="./assets/images/team/hugo.JPG" alt="empleados">
              <div class="card-body bg-danger">
                <h4 class="card-title text-white">Hugo Cabrera</h4>
                <p class="card-text text-white">Responsable Auxiliar Almacen</p>
              </div>
            </div>
            <div class="card text-left  shadow shadow-sm">
              <img class="card-img-top" src="./assets/images/team/eric.JPG" alt="empleados">
              <div class="card-body bg-danger">
                <h4 class="card-title text-white">Eric Viera</h4>
                <p class="card-text text-white">Responsable Almacen-Carga</p>
              </div>
            </div>
            <div class="card text-left  shadow shadow-sm">
              <img class="card-img-top" src="./assets/images/team/gon.JPG" alt="empleados">
              <div class="card-body bg-primary">
                <h4 class="card-title text-white">Ledesma Gonzalo</h4>
                <p class="card-text text-white">Responsable C.A.C.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

  </div>
</div>