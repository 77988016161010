<div id="servicios" class="row m-0 pt-5">
  <div class="col-12">
    <h2 class="text-center mt-5">NUESTROS SERVICIOS</h2>
    <p class="px-md-5 pt-0 text-justify text-wrap w-100">
      Para llevar un registro ordenado de sus operaciones, sus obligaciones legales y sus derechos de cualquier tipo,
      toda empresa debe destinar parte de sus recursos a la gestión y el mantenimiento de grandes cantidades de
      documentación. Ahora, implementando un sistema de última generación, certificado bajo normas ISO 9001, INTERFILE
      le garantiza contar con un archivo ágil, seguro, siempre disponibe y fundamentalmente económico, ahorrando tiempo,
      espacio y recursos humanos.
    </p>
  </div>
</div>
<div class="row m-0 mt-4">
  <div class="col-12 col-md-6">
    <div class="card mb-3 border-0">
      <div class="row g-0">
        <div class="col-md-4">
          <img class="img-fluid" src="./assets/images/services/remote-team.svg" alt="...">
        </div>
        <div class="col-md-8">
          <div class="card-body">
            <h5 class="card-title">Relevamiento, Diagnóstico y Presupuesto</h5>
            <p class="card-text p-0">
              Realizamos un relevamiento del archivo de su empresa para detectar posibles falencias tanto edilicias como
              de organización. Con esta información, se elabora un diagnóstico, un programa de refuncionalización y un
              presupuesto.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-3 border-0">
      <div class="row g-0">
        <div class="col-md-4">
          <img class="img-fluid" src="./assets/images/services/website.svg" alt="...">
        </div>
        <div class="col-md-8">
          <div class="card-body">
            <h5 class="card-title">Registro e Informatización</h5>
            <p class="card-text p-0">
              Trasvasamos el material clasificado a los contenedores especiales Interfile, registramos el contenido de cada uno de ellos e informatizamos dichos registros.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-3 border-0">
      <div class="row g-0">
        <div class="col-md-4">
          <img class="img-fluid" src="./assets/images/services/hotel.svg" alt="...">
        </div>
        <div class="col-md-8">
          <div class="card-body">
            <h5 class="card-title">Custodia</h5>
            <p class="card-text p-0">
              Almacenamos en nuestra planta los archivos que nos son confiados, permaneciendo en instalaciones del cliente sólo aquello que él mismo disponga.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6">
    <div class="card mb-3 border-0">
      <div class="row g-0">
        <div class="col-md-4">
          <img class="img-fluid" src="./assets/images/services/task.svg" alt="...">
        </div>
        <div class="col-md-8">
          <div class="card-body">
            <h5 class="card-title">Clasificación y Ordenamiento</h5>
            <p class="card-text p-0">
              Aprobado el programa, reagrupamos el material y lo disponemos en series documentales, regidas por los
              criterios más convenientes
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-3 border-0">
      <div class="row g-0">
        <div class="col-md-4">
          <img class="img-fluid" src="./assets/images/services/optimize-image.svg" alt="...">
        </div>
        <div class="col-md-8">
          <div class="card-body">
            <h5 class="card-title">Digitalización</h5>
            <p class="card-text p-0">
              Este servicio le permite disponer de sus documentos en formato digital y con un software a la medida de sus necesidades.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-3 border-0">
      <div class="row g-0">
        <div class="col-md-4">
          <img class="img-fluid" src="./assets/images/services/joy.svg" alt="...">
        </div>
        <div class="col-md-8">
          <div class="card-body">
            <h5 class="card-title">Atención al Cliente: Consultas</h5>
            <p class="card-text p-0 ">
              Ingresando con su password a nuestra página web podrá efectuar el pedido de documentación e insumos que necesite. Se lo enviamos mediante nuestro sistema de mensajería, por fax o internet, conforme los plazos y modalidad que nos indique.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>