import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-franquicias',
  templateUrl: './franquicias.component.html',
  styleUrls: ['./franquicias.component.sass']
})
export class FranquiciasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
